.css-1chwz6k-MuiPaper-root-MuiDialog-paper {
    margin: 0 !important;
    width: 100% !important;
    bottom: 0px !important;
    position: absolute !important;
    height: calc(100% - 96px) !important;
}
/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    margin: 0 !important;
} */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    margin: 8px !important;
}
.styled-dialog {
    margin: 0 !important;
}

/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
.MuiAccordionSummary-content {
    width: 100%;
}